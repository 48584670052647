<template>
  <div class="Slack">
    <ValidationObserver ref="observer" v-slot="{ invalid, passes }">
      <div v-if="general">
        <div class="description">
          In order to use slack notifications you should enable
          <a href="https://my.slack.com/services/new/incoming-webhook/" target="_blank">Incoming Webhooks</a>.
          Then all you need to do is fill webhook URL for each channel you want to receive notifications to.
          <br>
          Webhooks limit: 10
        </div>
        <base-alert type="error" v-if="generalError && generalHyperlink">
          The following monitor exceeded the maximum number of emails: <a :href=generalHyperlink>{{generalError}}</a>
        </base-alert>
        <base-alert type="error" v-else-if="generalError">
          {{generalError}}
        </base-alert>
        <div class="webhook-list">
          <div class="webhook"
               v-for="(channel, index) in userGeneralNotificationChannels"
               :key="index">
            <div class="remove"
                 @click.prevent="deleteGeneralChannel(channel)">
              <icon-trash width="18"
                          height="18" />
            </div>
            <div class="text">{{ channel.item_value }}</div>
          </div>
        </div>

        <div class="d-flex align-items-center mb-4">
          <base-textbox ref="newWebhook"
                        class="flex-grow-1 mb-0"
                        placeholder="Webhook URL"
                        v-model="newWebhook" />
          <base-button class="flex-shrink-0 ml-2"
                       color="primary"
                       :disabled="webhookAlreadyAdded || !newWebhook"
                       :loading="submitting"
                       @click.prevent="onFormSubmit">Add Webhook</base-button>
        </div>
      </div>
      <div v-else>
        <form ref="form"
              @submit.prevent="passes(onFormSubmit)">
          <div class="description">
            In order to use slack notifications you should enable
            <a href="https://my.slack.com/services/new/incoming-webhook/" target="_blank">Incoming Webhooks</a>.
            Then all you need to do is fill webhook URL for each channel you want to receive notifications to.
            <br>
            Webhooks limit: 10
          </div>

          <base-alert type="error" v-if="newWebHookError" class="mt-3">
            {{newWebHookError}}
          </base-alert>

          <div class="webhook-list">
            <div class="webhook"
                 v-for="(channel, index) in webhooks"
                 :key="index">
              <div class="remove"
                   @click.prevent="deleteChannel(channel)">
                <icon-trash width="18"
                            height="18" />
              </div>
              <div class="text">{{ channel.slack_webhook }}</div>
            </div>
          </div>

          <div class="d-flex align-items-center mb-4" v-if="webhooks.length < 10">
            <base-textbox ref="newWebhook"
                          class="flex-grow-1 mb-0"
                          placeholder="Webhook URL"
                          v-model="newWebhook" />
            <base-button class="flex-shrink-0 ml-2"
                         color="primary"
                         :disabled="webhookAlreadyAdded || !newWebhook"
                         :loading="submitting"
                         @click.prevent="onFormSubmit">Add Webhook</base-button>
          </div>

          <p class="already-added"
             v-if="webhookAlreadyAdded">This webhook is already added</p>

          <base-alert v-if="webhooks.length === 0">
            You have not specified any webhooks so far.
          </base-alert>

        </form>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import notificationChannelsApi from '@/api/monitorNotifications/notificationChannelsApi.js'
import slackApi from '@/api/monitorNotifications/slackApi.js'
import usersApi from '../../../../api/usersApi'

export default {
  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      webhooks: [],

      newWebhook: '',
      webhookAlreadyAdded: false,

      submitting: false,
      newWebHookError: false,
      userGeneralNotificationChannels: null,
      generalError: false,
      generalHyperlink: false
    }
  },

  created () {
    if (this.general) {
      this.loadGeneralNotificationChannels()
    }
  },

  mounted () {
    this.flushNewHookFocus()
  },

  methods: {
    async onFormSubmit () {
      if (this.general) {
        this.submitting = true
        try {
          await slackApi.generalStoreWebhook(this.newWebhook)
          await this.loadGeneralNotificationChannels()
          this.newWebhook = ''
        } catch (error) {
          this.generalError = error.response.data.errorMonitor ?? 'Something went wrong'
          this.generalHyperlink = error.response.data.hyperlink ?? false
        }

        this.submitting = false
      } else {
        this.newWebHookError = false
        if (this.webhookAlreadyAdded) {
          return false
        }

        this.submitting = true
        try {
          await slackApi.storeWebhook(this.monitor.id, this.newWebhook)
          this.$emit('update')
          this.newWebhook = ''
        } catch (error) {
          this.newWebHookError = error.response.data.errors.webhook_url[0] ?? 'Something went wrong'
        }

        this.submitting = false

        this.flushNewHookFocus()
      }
    },

    async deleteChannel (channel) {
      await notificationChannelsApi.delete(channel.id)
      this.$emit('update')
    },

    flushNewHookFocus () {
      if (this.$refs.newWebhook) {
        this.$refs.newWebhook.focus()
      }
    },

    async loadGeneralNotificationChannels () {
      try {
        this.userGeneralNotificationChannels = await usersApi.getGeneralChannels('slack_webhook')
      } catch (e) {
        this.generalError = 'Something went wrong'
      }
    },

    async deleteGeneralChannel (channel) {
      await notificationChannelsApi.generalDelete(channel.id)
      await this.loadGeneralNotificationChannels()
    }
  },

  watch: {
    monitor: {
      handler: function () {
        if (!this.general) {
          this.webhooks = this.monitor.notification_channels
            .filter(channel => channel.via === 'slack_webhook')
        }
      },
      deep: true,
      immediate: true
    },

    newWebhook: {
      handler: function (newWebhook) {
        if (!this.general) {
          this.webhookAlreadyAdded = false

          this.webhooks.forEach(channel => {
            if (channel.slack_webhook === newWebhook) {
              this.webhookAlreadyAdded = true
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Slack {
    max-width: 550px;

    .description {
      margin-bottom: 10px;
      color: #777;
      line-height: 1.5;
    }

    .webhook-list {
      max-width: 400px;
      margin-bottom: 10px;

      .webhook {
        display: flex;
        align-items: center;

        .remove {
          cursor: pointer;
          padding: 10px 10px 10px 0;

          &:hover ::v-deep svg g {
            transition: 0.2s all ease;
            fill: map-get($colors, purple-1) !important;
          }
        }

        .text {
          margin-bottom: 3px;
        }
      }
    }

    .already-added {
      margin-top: 10px;
    }
  }
</style>
